import React from 'react'
import { SkillsContainer, SkillsH1, SkillsWrapper, SkillsCard, SkillsIcon, SkillsH2, SkillsP} from './SkillsElements';
import Icon1 from '../../images/svg-4.svg';
import Icon2 from '../../images/svg-5.svg';
import Icon3 from '../../images/svg-6.svg';
import Icon4 from '../../images/svg-7.svg';
import Icon5 from '../../images/svg-8.svg';
import Icon6 from '../../images/svg-9.svg';
import Icon7 from '../../images/svg-10.svg';
import Icon8 from '../../images/svg-11.svg';

import {HeroBg, VideoBg } from '../HeroSection/HeroElements';
const video2 = 'https://bucket1689.s3.ap-east-1.amazonaws.com/video2.mp4';

const Skills = () => {
    return (
        <SkillsContainer id="skills">
            <HeroBg>
                <VideoBg autoPlay loop muted src={video2} type='video/mp4' />
            </HeroBg>
            <SkillsH1>Development Skills</SkillsH1>
            <SkillsWrapper>
                <SkillsCard>
                    <SkillsIcon src={Icon1}/>
                    <SkillsH2>JavaScript, CSS</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon2}/>
                    <SkillsH2>React.js, React Native</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon3}/>
                    <SkillsH2>Node.js, Express.js</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon4}/>
                    <SkillsH2>PostgreSQL, MongoDB</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon5}/>
                    <SkillsH2>Google Firebase</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon6}/>
                    <SkillsH2>Amazon Web Services</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon7}/>
                    <SkillsH2>Swift</SkillsH2>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon8}/>
                    <SkillsH2>Python</SkillsH2>
                </SkillsCard>
            </SkillsWrapper>
            
        </SkillsContainer>
    )
}

export default Skills
