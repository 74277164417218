import React, {useState} from 'react';
import { 
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper, 
    ArrowForward, 
    ArrowRight,
    AnimatedSection 
} from './HeroElements';
import { Button } from '../ButtonElement';

const video = 'https://bucket1689.s3.ap-east-1.amazonaws.com/video.mp4';

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay={true} loop={true} muted={true} src={video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
               <HeroH1>“Every accomplishment starts with the decision to try.”</HeroH1>
                <HeroP>– John F. Kennedy</HeroP>
                <HeroBtnWrapper>
                    <Button 
                      to="about"
                      onMouseEnter={onHover} 
                      onMouseLeave={onHover} 
                      primary="true" 
                      dark="true">
                        Let's Discover My Site {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
