import Svg1 from '../../images/svg-1.svg';
import Svg2 from '../../images/svg-2.svg';
import Svg3 from '../../images/svg-3.svg';

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Freelance Developer',
    headline: 'Implement Web and Mobile Development',
    description: 'During the my tenure in PwC, I have discovered an IT automation opportunity in Accounting area and I have started my journey in studying programming.  JavaScript is the first language that I have learnt and I have developed both Web and Mobile application development skills.',
    buttonLabel: 'Search My Experience',
    imgStart: false,
    img: Svg1,
    alt: 'Programming',
    dark: true,
    primary: true,
    darkText: false,
    directary: 'intro',
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'My Devemlopment Skills',
    headline: 'Both Frontend and Backend',
    description: 'My Skillsets across both Frontend and Backend area in Web and Mobile application development. I have also developed my skills with Python, Cloud Functions and sufficient knowledge in Blockchain.',
    buttonLabel: 'Discover My Skills',
    imgStart: true,
    img: Svg2,
    alt: 'Programming',
    dark: true,
    primary: true,
    darkText: true,
    directary: 'skills',
}

export const homeObjThree = {
    id: 'services',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'My Projects ',
    headline: "See What's Inside My Portfolio",
    description: 'After I have joined Galaxy One, I am now developing a few projects, including the Company Website, Educational Dashboard and a Conversational Application.  Additionally, I am also currently developing a dating application.',
    buttonLabel: 'Explore My Projects',
    imgStart: false,
    img: Svg3,
    alt: 'Programming',
    dark: true,
    primary: true,
    darkText: false,
    directary: 'projects',
}