import React from 'react'; 
import './gallery.css';
import img1 from '../../gallery/img-1.jpg';
import img2 from '../../gallery/img-2.jpg';
import img3 from '../../gallery/img-3.jpg';
import img4 from '../../gallery/img-4.jpg';
import img5 from '../../gallery/img-5.jpg';
import img6 from '../../gallery/img-6.jpg';
import img7 from '../../gallery/img-7.jpg';

import {HeroBg, VideoBg } from '../HeroSection/HeroElements';
const video3 = 'https://bucket1689.s3.ap-east-1.amazonaws.com/video3.mp4';

const Gallery = () => {
    return (
      <div className="services-section" id="projects">
        <HeroBg>
            <VideoBg autoPlay loop muted src={video3} type='video/mp4' />
        </HeroBg>
        <div className="services-heading">
          <h1>Check Out My Projects Below</h1>
        </div>
        <div className="services">
  
          <div className="services-cell">
            <img src={img1} alt="" className="services-cell_img" />
            <div className="services-cell_text">Galaxy One Company Website</div>
          </div>
  
          <div className="services-cell">
            <img src={img2} alt="" className="services-cell_img" />
            <div className="services-cell_text">Conversational Application</div>
          </div>
  
          <div className="services-cell">
            <img src={img3} alt="" className="services-cell_img" />
            <div className="services-cell_text">Dating Application</div>
          </div>
  
          <div className="services-cell">
            <img src={img4} alt="" className="services-cell_img" />
            <div className="services-cell_text">Educational Dashboard</div>
          </div>
  
          <div className="services-cell">
            <img src={img5} alt="" className="services-cell_img" />
            <div className="services-cell_text">Consulting</div>
          </div>
  
          <div className="services-cell">
            <img src={img6} alt="" className="services-cell_img" />
            <div className="services-cell_text">Coming Soon</div>
          </div>
  
          <div className="services-cell">
            <img src={img7} alt="" className="services-cell_img" />
            <div className="services-cell_text">Coming Soon</div>
          </div>
  
        </div>
      </div>
    );
  }
  
  export default Gallery;
  