import React, { useState } from 'react';
import {HeroBg, VideoBg } from '../HeroSection/HeroElements';
import { 
    IntroContainer, 
    SkillsH1, 
    SkillsWrapper, 
    SkillsCard, 
    SkillsIcon, 
    SkillsH2,
    SkillsP 
} from './AboutMeElements';

import Icon1 from '../../images/intro1.png';
import Icon2 from '../../images/intro2.png';
import Icon3 from '../../images/intro3.png';
import Icon4 from '../../images/intro4.svg';
import Icon5 from '../../images/intro5.png';
import Icon6 from '../../images/intro6.png';
import PopUpModal from '../PopUpModal';

const video4 = 'https://bucket1689.s3.ap-east-1.amazonaws.com/video4.mp4';

const AboutMe = () => {
    const [toggle, setToggle] = useState(false);
    const togglePopUpModal = () => {
        return setToggle(true);
    };

    return (
        <IntroContainer id="intro">
            <HeroBg>
                <VideoBg autoPlay loop muted src={video4} type='video/mp4' />
            </HeroBg>
            <SkillsH1>My Experience</SkillsH1>
            <SkillsWrapper>
                <SkillsCard onClick={togglePopUpModal}>
                    <button>
                        <SkillsIcon src={Icon1} />
                        <SkillsH2>Graduated From Trinity College</SkillsH2>
                        <SkillsP>Jan 2015 - Dec 2015</SkillsP>
                    </button>
                </SkillsCard>
                {toggle ? <PopUpModal setToggle={setToggle}/> : null}
                <SkillsCard>
                    <SkillsIcon src={Icon2}/>
                    <SkillsH2>Graduated From Macquarie University</SkillsH2>
                    <SkillsP>Feb 2016 - Dec 2018</SkillsP>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon3}/>
                    <SkillsH2>Worked as Tax Consultant</SkillsH2>
                    <SkillsH2>PricewaterhouseCoopers Hong Kong</SkillsH2>
                    <SkillsP>Feb 2019 - Feb 2021</SkillsP>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon4}/>
                    <SkillsH2>Learnt JavaScript and React.js</SkillsH2>
                    <SkillsH2>Node.js, React Native</SkillsH2>
                    <SkillsP>Feb 2020 - Jul 2020</SkillsP>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon5}/>
                    <SkillsH2>Joined Galaxy One </SkillsH2>
                    <SkillsH2> Freelance Web & Mobile Developer</SkillsH2>
                    <SkillsP>Nov 2020 - Present</SkillsP>
                </SkillsCard>
                <SkillsCard>
                    <SkillsIcon src={Icon6}/>
                    <SkillsH2>Studying Blockchain Specialization</SkillsH2>
                    <SkillsP>The State University of New York at Buffalo</SkillsP>
                    <SkillsP>Feb 2021 - Present</SkillsP>
                </SkillsCard>
            </SkillsWrapper>
        </IntroContainer>
    )
}

export default AboutMe
