import React from 'react';
import {FaLinkedin, FaInstagram, FaFacebook, FaTwitter} from 'react-icons/fa'
import { 
    FooterContainer, 
    FooterWrap, 
    FooterLinkContainer, 
    FooterLinkWrapper, 
    FooterLinkItems, 
    FooterLinkTitle, 
    FooterLink, 
    SocialMedia, 
    SocialMediaWrap, 
    SocialLogo, 
    SocialIcons, 
    SocialIconLink,
    WebsiteRights
} from './FooterElements';

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinkContainer>
                    <FooterLinkWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                                <FooterLink to="/contact">How it works</FooterLink>
                                <FooterLink to="/contact">Testimonials</FooterLink>
                                <FooterLink to="/contact">Careers</FooterLink>
                                <FooterLink to="/contact">Investors</FooterLink>
                                <FooterLink to="/contact">Terms of Services</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact Us</FooterLinkTitle>
                                <FooterLink to="/contact">How it works</FooterLink>
                                <FooterLink to="/contact">Testimonials</FooterLink>
                                <FooterLink to="/contact">Careers</FooterLink>
                                <FooterLink to="/contact">Investors</FooterLink>
                                <FooterLink to="/contact">Terms of Services</FooterLink>
                        </FooterLinkItems>
                    </FooterLinkWrapper>
                </FooterLinkContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'>Benson Chong</SocialLogo>
                        <WebsiteRights>Benson Chong © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//www.linkedin.com/in/bensonchong1689" target="_blank" aria-label="LinkedIn">
                                <FaLinkedin />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
