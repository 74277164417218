import styled from 'styled-components';

export const IntroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 80px;
  height: 900px;
  position: relative;
  z-index: 1;
  flex-direction: column;

  :before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
      linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
      z-index: 2;
    }
    @media screen and (max-width: 1000px) {
      padding: 100px 0;
      height: 1800px;
  }
    @media screen and (max-width: 768px) {
      padding: 100px 0;
      height: 3000px;
  }
`

export const SkillsWrapper = styled.div`
  max-width: 1150px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  padding: 0 50px;
  z-index: 3;

  @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
  }
`

export const SkillsCard = styled.div`
  background: #fff;
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
      transform: scale(1.12);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
  }
`

export const SkillsIcon = styled.img`
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
`

export const SkillsH1 = styled.h1`
  font-size: 48px;
  color: #fff;
  /* margin-bottom: 64px; */
  z-index: 3;

  @media screen and (max-width: 480px) {
      font-size: 32px;
  }
`

export const SkillsH2 = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 10px;
`

export const SkillsP = styled.p`
  font-size: 1rem;
  text-align: center;
`