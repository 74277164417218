import styled from "styled-components";

export const ContactContainer = styled.div`
  display: "flex";
  flex-direction: "row";
  width: "100%";
  height: "100%";
  padding: "1em"
`

export const Header = styled.h1``

export const Input = styled.input``

export const Form = styled.form`
  display: "flex";
  flex-direction: "row";
  // width: "100%";
  // height: "100%";
  justify-content: "center";
  align-items: "center";
  background-color: "yellow";
`

export const FieldSet = styled.fieldset`
  display: "flex";
  justify-content: "center";
  width: "50%";
`

export const TextArea = styled.textarea`
  min-height: "200px";
`

export const Button = styled.button`

`
