import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from "../components/Navbar";
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/Data';
import Skills from '../components/Skills';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import AboutMe from '../components/AboutMe';

const mainContainer = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    margin: 0,
    backgroundColor: 'black',
}

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div style={mainContainer}>
          <Sidebar isOpen={isOpen} toggle={toggle}/>
          <Navbar toggle={toggle}/>  
          <HeroSection /> 
          <InfoSection {...homeObjOne}/>
          <AboutMe/>
          <InfoSection {...homeObjTwo}/>
          <Skills />
          <InfoSection {...homeObjThree}/>
          <Gallery/>
          <Footer />
        </div>
    )
}

export default Home
