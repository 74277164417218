import React from 'react';
import {CloseButton, ModalContainer, ModalContentContainer} from './PopUpModalElements';

const PopUpModal = (props) => {
  const {setToggle} = props;
  const handleClose = () => {
    return setToggle(false);
  }
  return (
    <ModalContainer>
      <ModalContentContainer>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        <p>I'm A Pop Up!!!</p>
      </ModalContentContainer>
    </ModalContainer>
  )
}

export default PopUpModal;
