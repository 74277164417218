
import './App.css';
import Gallery from './components/Gallery';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages';
import ContactMePage from './pages/ContactMePage/index';
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contact" component={ContactMePage} exact />
      </Switch>
    </Router>
  );
}

export default App;
