import React, {useState} from 'react';
import {HeroBg, VideoBg} from '../../components/HeroSection/HeroElements';
import Navbar from '../../components/Navbar';
import styles from './styles.css';
import {Form, FieldSet, TextArea, ContactContainer, Header, Input, Button} from './StyledElements';

const video = 'https://bucket1689.s3.ap-east-1.amazonaws.com/video.mp4';

function ContactMePage() {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const [mailerState, setMailerState] = useState({
        name: "",
        email: "",
        message: "",
    });

    function handleStateChange(e) {
        setMailerState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
    }

    const submitEmail = async (e) => {
        e.preventDefault();
        console.log({ mailerState });
        const response = await fetch("http://localhost:5001/send", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ mailerState }),
        })
          .then((res) => res.json())
          .then(async (res) => {
            const resData = await res;
            console.log(resData);
            if (resData.status === "success") {
              alert("Message Sent");
            } else if (resData.status === "fail") {
              alert("Message failed to send");
            }
          })
          .then(() => {
            setMailerState({
              email: "",
              name: "",
              message: "",
            });
          });
      };

    return (
        <div 
        className="App"
        style={{
            backgroundColor: '#EEE1C6',
        }}>
            <Navbar toggle={toggle}/>  
            <HeroBg>
                <VideoBg autoPlay={true} loop={true} muted={true} src={video} type='video/mp4' />
            </HeroBg>
            <div                 
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    // zIndex: 1,
                    width: "40%",
                    height: "70%",
                    backgroundColor: 'rgba(54,69,79,0.3)',
                    position: "fixed",
                    justifyContent: "center",
                    alignSelf: "center",
                    padding: 24,
                    borderRadius: 20,
                }}>
                <p style={{color: "#EEE1C6", margin: 8, fontWeight: 'bold', fontSize: 24,}}>Name</p>
                <input                         
                    style={{ 
                        fontSize: 20,
                        minHeight: "30px",
                        backgroundColor: "rgba(238,225,198,0.7)",
                        color: "#36454F",
                        margin: 8,
                        // borderRadius: 10,
                    }}
                    // placeholder="Name"
                    onChange={handleStateChange}
                    name="name"
                    value={mailerState.name}
                />
                <p style={{color: "#EEE1C6", margin: 8, fontWeight: 'bold', fontSize: 24,}}>Email</p>
                <input
                    style={{ 
                        fontSize: 20,
                        minHeight: "30px",
                        backgroundColor: "rgba(238,225,198,0.7)",
                        color: "#36454F",
                        margin: 8,
                        // borderRadius: 10,
                    }}
                    // placeholder="Email"
                    onChange={handleStateChange}
                    name="email"
                    value={mailerState.email}
                />
                <p style={{color: "#EEE1C6", margin: 8, fontWeight: 'bold', fontSize: 24,}}>Message</p>
                <textarea
                    style={{ 
                        fontSize: 20,
                        minHeight: "200px",
                        backgroundColor: "rgba(238,225,198,0.7)",
                        color: "#36454F",
                        margin: 8,
                        // borderRadius: 10,
                    }}
                    // placeholder="Message"
                    onChange={handleStateChange}
                    name="message"
                    value={mailerState.message}
                />
                <button 
                    style={{
                        backgroundColor: "rgba(54,69,79,0.5)",
                        color: "#EEE1C6",
                        fontSize: 20,
                        // fontWeight: "bold",
                        borderRadius: 5,
                        margin: 16,
                        width: "20%",
                        alignSelf: "flex-end",
                    }}
                    onClick={submitEmail}
                >
                    Send
                </button>  
            </div>
        </div>
    )
}

export default ContactMePage;
